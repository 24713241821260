export const environment = {
	production: false,
	firebaseConfig: {
		apiKey: 'AIzaSyASYwOfz1bPhreaBK0dOUX7CSC7W4zZc1s',
		authDomain: 'array-app-qa.firebaseapp.com',
		projectId: 'array-app-qa',
		storageBucket: 'array-app-qa.appspot.com',
		messagingSenderId: '1056033312343',
		appId: '1:1056033312343:web:d1ca8bbaab3eed35001d12',
		apiUrl: 'https://us-central1-array-app-qa.cloudfunctions.net/api'
	}
};
